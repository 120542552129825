import { useState } from 'react';
import { Button, Drawer } from 'antd';
import { useMediaQuery } from 'react-responsive';

import { CustomFilter } from '../../components/filter/CustomFilter';
import { ChartPrice } from './components/ChartsPrice';
import { ChartVolume } from './components/ChartVolume';
// import { ChartWeek } from './components/ChartsWeek';
import styles from './styles.module.scss';
import { isTobacco } from '../../utils/isTobacco';
import { FilterOutlined } from '@ant-design/icons';

export const BuyingBehaviorPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [openFilter, setOpenFilter] = useState(false);
  const canViewPrice = JSON.parse(localStorage.getItem('user'))?.role?.permissions['buying_behavior_price']?.includes(
    'can_read'
  );
  const canViewWeek = JSON.parse(localStorage.getItem('user'))?.role?.permissions['buying_behavior_week']?.includes(
    'can_read'
  );
  const canViewVolume = JSON.parse(localStorage.getItem('user'))?.role?.permissions['buying_behavior_volume']?.includes(
    'can_read'
  );

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  return (
    <div className={styles.company_layout_content}>
      <div className={styles.top_bar}>
        <div className={styles.wrapper}>
          {!isTabletOrMobile && <div className={styles.title}>Buying behavior</div>}
          {!isTabletOrMobile && (
            <>
              <div style={{ marginLeft: '15px' }}>
                <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                  <CustomFilter onFilterChange={onFilterChange} onCancel={hide} pageName="sales" />
                </Drawer>
                <Button
                  type="ghost"
                  className={styles.company_filter_btn}
                  onClick={onOpenFilter}
                  icon={<FilterOutlined />}
                >
                  &nbsp;&nbsp;Filter
                </Button>
              </div>
            </>
          )}
        </div>
      </div>

      {isTabletOrMobile && (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
          <div>
            <Drawer title="" placement="left" onClose={hide} open={openFilter}>
              <CustomFilter onFilterChange={onFilterChange} onCancel={hide} pageName="sales" />
            </Drawer>
            <Button
              type="ghost"
              className={styles.company_filter_btn}
              onClick={onOpenFilter}
              icon={<FilterOutlined />}
            />
          </div>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: isTabletOrMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginTop: isTabletOrMobile ? 16 : 0,
        }}
      >
        {canViewPrice && <ChartPrice isTabletOrMobile={isTabletOrMobile} />}
        {/* {canViewWeek && <ChartWeek />} */}
        {!isTobacco() && canViewVolume && <ChartVolume isTabletOrMobile={isTabletOrMobile} />}
      </div>
    </div>
  );
};
