import { Checkbox, Form, Switch, Typography } from 'antd';
import styles from './styles.module.scss';

const { Text } = Typography;
type Props = {
  isModal: boolean;
  permission?: any;
};

const defaultStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 12,
};

export const AnalysisFormItemsNew = ({ isModal, permission }: Props) => {
  return (
    <>
      {/* <div className={isModal ? styles.manageColumn : styles.manage}> */}
      <div className={styles.item}>
        <Text type="secondary">View: </Text>

        <Form.Item style={defaultStyles}>
          <Form.Item valuePropName="checked" name={['abc_analysis_barcode']} noStyle>
            <Checkbox />
          </Form.Item>
          <label>&nbsp;&nbsp;Barcode ABC analysis</label>
        </Form.Item>

        <Form.Item style={defaultStyles}>
          <Form.Item valuePropName="checked" name={['out_of_stock_barcode']} noStyle>
            <Checkbox />
          </Form.Item>
          <label>&nbsp;&nbsp;Barcode Out of stock</label>
        </Form.Item>

        <Form.Item style={defaultStyles}>
          <Form.Item valuePropName="checked" name={['price_elasticity']} noStyle>
            <Checkbox />
          </Form.Item>
          <label>&nbsp;&nbsp;Price elasticity</label>
        </Form.Item>

        <Form.Item style={defaultStyles}>
          <Form.Item valuePropName="checked" name={['product_comparison']} noStyle>
            <Checkbox />
          </Form.Item>
          <label>&nbsp;&nbsp;Product comparison</label>
        </Form.Item>

        <Form.Item style={defaultStyles}>
          <Form.Item valuePropName="checked" name={['coexistence']} noStyle>
            <Checkbox />
          </Form.Item>
          <label>&nbsp;&nbsp;Co-Existence</label>
        </Form.Item>

        <Form.Item style={defaultStyles}>
          <Form.Item valuePropName="checked" name={['coexistence_trends']} noStyle>
            <Checkbox />
          </Form.Item>
          <label>&nbsp;&nbsp;Co-Existence Trends</label>
        </Form.Item>

        <Form.Item style={defaultStyles}>
          <Form.Item valuePropName="checked" name={['vpo_segmentation']} noStyle>
            <Checkbox />
          </Form.Item>
          <label>&nbsp;&nbsp;VPO-Segmentation</label>
        </Form.Item>

        <Form.Item style={defaultStyles}>
          <Form.Item valuePropName="checked" name={['vpo_trend']} noStyle>
            <Checkbox />
          </Form.Item>
          <label>&nbsp;&nbsp;VPO-Segmentation Trends</label>
        </Form.Item>

        {permission === 601 && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['basket_analysis']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Basket Analysis</label>
          </Form.Item>
        )}
      </div>

      <div>
        <Text type="secondary">View buying behavior chart:</Text>
        <Form.Item style={defaultStyles}>
          <Form.Item valuePropName="checked" name={['buying_behavior_price']} noStyle>
            <Checkbox />
          </Form.Item>
          <label>&nbsp;&nbsp;Price</label>
        </Form.Item>

        <Form.Item style={defaultStyles}>
          <Form.Item valuePropName="checked" name={['buying_behavior_week']} noStyle>
            <Checkbox />
          </Form.Item>
          <label>&nbsp;&nbsp;Week</label>
        </Form.Item>

        <Form.Item style={defaultStyles}>
          <Form.Item valuePropName="checked" name={['buying_behavior_volume']} noStyle>
            <Checkbox />
          </Form.Item>
          <label>&nbsp;&nbsp;Volume</label>
        </Form.Item>
      </div>
      {/* </div> */}
    </>
  );
};
