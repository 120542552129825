import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { PageQueryParams } from '../models/pageQueryParams';
import fetchClient from './axiosInstance';

interface StoresQueryParams extends PageQueryParams {
  period_after: string;
  period_before: string;
  mode: string | 'exclude' | 'include';
  city?: string;
  brand?: string;
  barcode?: string;
  local_company?: string;
  size_of_package?: string;
  subcategory?: string;
  type_of_package?: string;
  active_stores?: boolean;
  validated_stores?: boolean;
  hashed_store_id?: string;
  share_min?: string;
  ordering?: 'value' | '-value' | 'my_share' | '-my_share';
}

interface StoresParams extends PageQueryParams {
  all?: boolean;
  search: string;
  hashed_store_id?: string;
}

interface StoreStatisticsQueryParams extends PageQueryParams {
  period_after: string;
  period_before: string;
  stores?: string;
  category?: string;
  mode?: string;
}

export default class StoresService {
  static async getSalesByStores(params: StoresQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    const body = {
      ...params,
      ordering: params.ordering || '-value',
    };
    return fetchClient.get(`/api/v1/sales_by_stores/`, { params: body, ...customConfig });
  }

  static async exportSalesByStores(
    params: StoresQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    const body = { ...params, ordering: '-value' };
    return fetchClient.get(`/api/v1/sales_by_stores/export/`, {
      params: body,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }

  static async getStoresStatistics(
    type: 'local_company' | 'brand' | 'barcode',
    params: StoresQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/store_statistics/${type}`, {
      params: { ...params, ordering: '-value' },
      ...customConfig,
    });
  }

  static async getStores(params: StoresQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/stores/`, { params: { ...params, ordering: '-value' }, ...customConfig });
  }

  static async exportStoresStatistics(
    type: 'local_company' | 'brand' | 'barcode',
    params: StoresQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/store_statistics/${type}/export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }

  static async exportDetailedStoresStatistics(
    params: StoresQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/store_statistics/detailed/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }

  static async getHashedStores(params: StoresParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/stores/hashed/`, { params, ...customConfig });
  }
}
