import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select, Spin, Typography, notification } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import PermissionsService from '../../../services/permissions';
import { PlusOutlined } from '@ant-design/icons';
import { CreateRole } from '../CreateRole/CreateRole';
import styles from './styles.module.scss';

const { Text } = Typography;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};

type Props = {
  id?: string;
  onCloseEditDrawer?: () => void;
};

export const CreateUserNew = ({ id, onCloseEditDrawer }: Props) => {
  const { userId } = useParams<{ userId: string }>();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState<{}>(null);
  const [roles, setRoles] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [form] = Form.useForm();

  const userCompanyId = JSON.parse(localStorage.getItem('user'))?.company?.id;

  const onNavigateTable = () => {
    onCloseEditDrawer();
    // history.push('/permissions/users');
    form.resetFields();
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.warning({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values: any) => {
    if (userId || id) {
      PermissionsService.editUserById(userId || id, { ...values, company: userCompanyId })
        .then((res) => {
          onNavigateTable();
        })
        .catch((err) => {
          openNotification(err.response?.data?.email[0]);
        });
    } else {
      PermissionsService.createUser({ ...values, company: userCompanyId })
        .then((res) => {
          onNavigateTable();
        })
        .catch((err) => {
          openNotification(err.response?.data?.email[0]);
        });
    }
  };

  useEffect(() => {
    if (userId || id) {
      PermissionsService.getUserById(userId || id).then((res) => {
        form.setFieldsValue({
          ...res.data,
          role: res?.data?.role?.id,
        });
      });
    }
    return () => {
      setUserInfo(null);
    };
  }, [userId, id]);

  useEffect(() => {
    PermissionsService.getRoles({ limit: 1000 })
      .then((res) => {
        setRoles(res.data.results.map((item: any) => ({ label: item['name'], value: item['id'] })));
      })
      .catch((err) => setRoles([]))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        className={styles.form}
        {...formItemLayout}
        layout="vertical"
        initialValues={userInfo || null}
      >
        {/* <Text type="secondary">Personal information</Text> */}
        <div>
          <div>
            <Typography style={{ margin: 0, padding: 0 }}>Name</Typography>
            <Form.Item name="first_name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </div>

          <div>
            <Typography style={{ margin: 0, padding: 0 }}>Last name</Typography>
            <Form.Item name="last_name">
              <Input />
            </Form.Item>
          </div>

          <div>
            <Typography style={{ margin: 0, padding: 0 }}>Username</Typography>
            <Form.Item name="username" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </div>

          <div>
            <Typography style={{ margin: 0, padding: 0 }}>Phone</Typography>
            <Form.Item name="phone" rules={[{ required: true }]}>
              <Input maxLength={11} />
            </Form.Item>
          </div>

          <div>
            <Typography style={{ margin: 0, padding: 0 }}>Email</Typography>
            <Form.Item name="email" rules={[{ required: true }]}>
              <Input type="email" />
            </Form.Item>
          </div>

          {!userId && (
            <div>
              <Typography style={{ margin: 0, padding: 0 }}>Password</Typography>
              <Form.Item name="password" rules={[{ required: true }]}>
                <Input type="password" />
              </Form.Item>
            </div>
          )}

          <Row>
            <Col span={16}>
              <Typography style={{ margin: 0, padding: 0 }}>Role</Typography>
              <Form.Item name="role" rules={[{ required: true }]}>
                <Select
                  options={roles || []}
                  allowClear={true}
                  placeholder="Choose a role"
                  notFoundContent={loading ? <Spin size="small" /> : null}
                />
              </Form.Item>
            </Col>
            <Col span={1}>
              <Button
                size="middle"
                htmlType="button"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={showModal}
                style={{ marginTop: '20px', marginLeft: '-70px' }}
              />
            </Col>
          </Row>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 18 }}>
          <Button type="default" htmlType="button" onClick={onNavigateTable}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
      <Modal title="Create new role" open={isModalOpen} footer={null} onCancel={handleCancel}>
        <CreateRole isModal link={history.location.pathname} close={handleCancel} />
      </Modal>
    </div>
  );
};
