import { useHistory } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { Button, Tag, Tooltip } from 'antd';
import { CheckCircleOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';

interface DataType {
  key: React.Key;
  name: string;
}

export const useRoleColumns = ({
  canEdit,
  setIsUserActive,
  setId,
  handleOpenModal,
  setIsEditOpen,
}: any): ColumnsType<DataType> => {
  const history = useHistory();
  return [
    {
      title: 'Role',
      width: 225,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'status',
      width: 225,
      render: (value: any) => (value?.is_active ? <Tag color="green">Active</Tag> : <Tag color="red">Not active</Tag>),
    },
    {
      title: 'users',
      width: 225,
      render: (value: any) => (
        <Button type="link" onClick={() => history.push(`/permissions/users?page=1&limit=10&role=${value.id}`)}>
          {value.users_count}
        </Button>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (value: any) => (
        <>
          {canEdit && (
            <Tooltip placement="top" title="Edit">
              <Button
                type="text"
                onClick={() => {
                  setId(value?.id);
                  // history.push(`/permissions/roles/${value.id}/edit`);
                  setIsEditOpen(true);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          )}

          {canEdit &&
            (value.is_active ? (
              <Tooltip placement="top" title="Block role">
                <Button
                  type="text"
                  onClick={() => {
                    setIsUserActive(false);
                    setId(value?.id);
                    handleOpenModal();
                  }}
                >
                  <StopOutlined />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip placement="top" title="Unblock role">
                <Button
                  type="text"
                  onClick={() => {
                    setIsUserActive(true);
                    setId(value?.id);
                    handleOpenModal();
                  }}
                >
                  <CheckCircleOutlined />
                </Button>
              </Tooltip>
            ))}
        </>
      ),
    },
  ];
};
