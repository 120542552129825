import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

export const ToggleMenuItems = (columns: Record<string, any>, handleToggle: (key: string) => void) => (
  <Menu>
    {Object.keys(columns).map((column: any, index) => (
      <Menu.Item key={column}>
        <div
          onClick={() => handleToggle(column)}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          {columns[column].title} {columns[column].value ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </div>
      </Menu.Item>
    ))}
  </Menu>
);
