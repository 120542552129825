import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Drawer } from 'antd';
import { FilterOutlined, InfoCircleOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import qs from 'qs';
import { useMediaQuery } from 'react-responsive';

import { CustomFilter } from '../../components/filter/CustomFilter';
import { OutOfStockTable } from './components/Table';
import OutOfStockService from '../../services/outOfStock';
// import { downloadFile } from '../../utils/downloadFile';
import { InformationModal } from './components/InformationModal';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import { openNotification } from '../../utils/lastThreeMonths';
import styles from './styles.module.scss';

export const OutOfStockPage = () => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [openFilter, setOpenFilter] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const handleDownloadFile = () => {
    setExportLoading(true);
    OutOfStockService.exportByType({
      months: params?.months,
      local_company: params?.local_company,
      ...params,
    })
      .then((res) => {
        setIsOpenDonwloads(true);
        openNotification('File added to downloads folder', 'success');
        // downloadFile(res.data, `out_of_stock_report_${params?.months}` || 'out_of_stock_report');
      })
      .finally(() => setExportLoading(false));
  };

  return (
    <>
      <div className={styles.company_layout_content}>
        <div className={styles.top_bar}>
          <div className={styles.wrapper}>
            {!isTabletOrMobile && (
              <div className={styles.title}>
                By Barcode
                <Button shape="circle" type="link" icon={<InfoCircleOutlined />} onClick={() => setOpenModal(true)} />
              </div>
            )}
            {isTabletOrMobile && (
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
                <div>
                  <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                    <CustomFilter
                      onFilterChange={onFilterChange}
                      onCancel={hide}
                      type="barcode"
                      pageName="abc-analysis"
                      disabledMode
                      reportName="out_of_stock"
                    />
                  </Drawer>
                  <Button
                    type="ghost"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  />
                </div>
              </div>
            )}
            {!isTabletOrMobile && (
              <>
                <div style={{ marginLeft: '15px' }}>
                  <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                    <CustomFilter
                      onFilterChange={onFilterChange}
                      onCancel={hide}
                      type="barcode"
                      pageName="abc-analysis"
                      disabledMode
                      reportName="out_of_stock"
                    />
                  </Drawer>
                  <Button
                    type="ghost"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  >
                    &nbsp;&nbsp;Filter
                  </Button>
                </div>
              </>
            )}
          </div>
          <Button type="default" className={styles.export_btn} onClick={handleDownloadFile} loading={exportLoading}>
            <VerticalAlignBottomOutlined />
            Export
          </Button>
        </div>

        <OutOfStockTable />
        <InformationModal open={openModal} close={handleCloseModal} />
      </div>
      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
    </>
  );
};
