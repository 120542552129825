import { useCallback } from 'react';
import { Form } from 'antd';

import { SelectItem } from '../form';
import { Subcategory } from '../../models';

type Props = {
  setSabcategoryString: (value: string) => void;
  subcategoriesArray: string[];
  subcategoriesData: Subcategory[];
  subcategoriesIsFetching: boolean;
  setSubcategoriesArray: (value) => void;
  validateStatus?: 'success' | 'warning' | 'error' | 'validating';
  disabled?: boolean;
};

export const SubcategoryFormItem = ({
  setSabcategoryString,
  subcategoriesArray,
  subcategoriesData,
  subcategoriesIsFetching,
  setSubcategoriesArray,
  validateStatus,
  disabled,
}: Props) => {
  const handleSubcategoryChange = useCallback(
    (value) => {
      setSabcategoryString(value?.join(','));
    },
    [setSabcategoryString]
  );

  const handleDropdownVisibleChange = useCallback(
    (open) => {
      if (!open) {
        handleSubcategoryChange(subcategoriesArray);
      }
    },
    [handleSubcategoryChange, subcategoriesArray]
  );

  const handleDeselectSubcategoryValue = useCallback(
    (val) => {
      const arr = subcategoriesArray?.filter((el) => el !== val);
      handleSubcategoryChange(arr);
    },
    [handleSubcategoryChange, subcategoriesArray]
  );

  return (
    <Form.Item validateStatus={validateStatus}>
      <label>Subcategory</label>
      <SelectItem
        name="subcategory"
        mode="multiple"
        options={subcategoriesData}
        placeholder="Choose a category"
        loading={subcategoriesIsFetching}
        maxTagCount="responsive"
        onDropdownVisibleChange={handleDropdownVisibleChange}
        onDeselect={handleDeselectSubcategoryValue}
        allowClear={true}
        disabled={disabled}
        onChange={(value) => {
          setSubcategoriesArray(value);
        }}
      />
    </Form.Item>
  );
};
