import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { notification, PaginationProps, Table, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from 'react-query';
import qs from 'qs';

import StoresService from '../../../services/stores';
import { useColumns } from './useColumns';
import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
  pageName: string;
  rule?: any;
  rowSelection?: any;
};

export const StoresTable = ({ pageName, rule, rowSelection }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [ordering, setOrdering] = useState<'value' | '-value' | 'my_share' | '-my_share'>('-value');
  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;
  const [totalInfo, setTotalInfo] = useState<any[]>([]);
  const regions = rule?.regions?.join(',');

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    history.replace({ pathname: history.location.pathname, search: queryParams.toString() });
  };

  const { data, isFetching, error } = useQuery(['stores', location.search, ordering], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    return StoresService.getSalesByStores({
      period_after: params?.period_after,
      period_before: params?.period_before,
      mode: params?.mode || 'include',
      city: params?.city || regions || '24',
      ordering: ordering,
      ...params,
    });
  });

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
      setTotalInfo(data?.data?.additional);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
        setTotal(0);
        setTotalInfo([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
        setTotal(0);
        setTotalInfo([]);
      }
    }

    return () => {
      setTableData([]);
      setTotal(0);
      setTotalInfo([]);
    };
  }, [data, error]);

  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: isTabletOrMobile ? '11px' : '14px',
  };

  const columnsParams = useMemo(
    () => ({ isTabletOrMobile, setOrdering, ordering }),
    [isTabletOrMobile, setOrdering, ordering]
  );
  const columns = useColumns(columnsParams);

  return (
    <>
      <Table
        columns={columns.filter((item: any) => !item['hidden']) || columns}
        rowSelection={rowSelection}
        rowKey={(record: any) => record?.hashed_store_id}
        dataSource={tableData}
        loading={isFetching}
        pagination={{
          pageSize: limit,
          defaultPageSize: 10,
          total: total,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
        className={styles.ant_table}
        sticky
        scroll={{ x: '100%' }}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} />
                <Table.Summary.Cell index={1} align="left">
                  <Text className={styles.total_bold}>
                    Total <p style={{ color: '#A9A9A9', fontWeight: 400, ...defaultStyles }}>Stores: {total || ''}</p>
                  </Text>
                </Table.Summary.Cell>
                {totalInfo[0] && (
                  <>
                    <Table.Summary.Cell align="center" index={2}>
                      <span className={styles.summary_cell}>
                        <Text className={styles.total_bold}>
                          {`${totalInfo[0]?.value.toString()?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}` || '--'}
                        </Text>
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center" index={3}>
                      <span className={styles.summary_cell}>
                        <Text className={styles.total_bold}>{`${totalInfo[0]?.my_share} %` || '--'}</Text>
                      </span>
                    </Table.Summary.Cell>
                  </>
                )}
              </Table.Summary.Row>
            </>
          );
        }}
        bordered
        size="middle"
      />
    </>
  );
};
