import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, notification, PaginationProps, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import qs from 'qs';
import { format } from 'date-fns';
import { useQuery } from 'react-query';

import styles from './styles.module.scss';
import PermissionsService from '../../services/permissions';

interface DataType {
  key: React.Key;
  name: string;
}

export const UserLogsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;

  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    history.replace({ pathname: history.location.pathname, search: queryParams.toString() });
  };

  const { data, isFetching, error } = useQuery(['logs', params], ({ signal }) => {
    return PermissionsService.getLastLogs({ limit: +params.limit || 10, ...params }, { signal });
  });

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  const columns = useMemo<ColumnsType<DataType>>(
    () => [
      {
        title: 'User',
        width: 155,
        key: 'username',
        render: (value: any) => {
          return (
            <Button type="link" onClick={() => history.push(`/users-logs/${value?.id}?page=1&limit=10`)}>
              {value?.username || '--'}
            </Button>
          );
        },
      },
      {
        title: 'Recent action time',
        dataIndex: 'last_action_time',
        key: 'last_action_time',
        width: 90,
        render: (value) => (value !== undefined && value === null ? '--' : format(new Date(value), 'HH:mm-dd.MM.yyyy')),
      },
    ],
    []
  );

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
        setTotal(0);
      }
    }
  }, [data, error]);

  return (
    <div className={styles.company_layout_content}>
      <div className={styles.top_bar}>
        <div className={styles.title}>Users logs</div>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={tableData}
          loading={isFetching}
          className={styles.ant_table}
          sticky
          pagination={{
            pageSize: limit,
            defaultPageSize: 10,
            total: total,
            onChange: onPageChange,
            current: page,
            defaultCurrent: 1,
          }}
          bordered
          size="middle"
        />
      </div>
    </div>
  );
};
