import { Checkbox, Form, Switch } from 'antd';

type Props = {
  isModal: boolean;
};

const defaultStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 12,
};

export const ShareByCitiesFormItemsNew = ({ isModal }: Props) => {
  return (
    <>
      Market share:
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['market_share_local_company']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View company reports</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['market_share_brand']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View brand reports</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['market_share_barcode']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View barcode reports</label>
      </Form.Item>
      Trends share:
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['share_trends_local_company']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View company reports</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['share_trends_brand']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View brand reports</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['share_trends_barcode']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View barcode reports</label>
      </Form.Item>
      Comparison share:
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['share_comparison_local_company']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View company reports</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['share_comparison_brand']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View brand reports</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['share_comparison_barcode']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View barcode reports</label>
      </Form.Item>
    </>
  );
};
