import { Form, Switch } from 'antd';

type Props = {
  isModal: boolean;
};

export const StoreFormItems = ({ isModal }: Props) => {
  return (
    <>
      Stores:
      <Form.Item
        valuePropName="checked"
        name={['sales_by_stores']}
        label="- View sales by stores"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['store_statistics']}
        label="- View store statistics"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
    </>
  );
};
