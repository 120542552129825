import { isTobacco } from '../../../utils/isTobacco';

export const useColumns = ({ pageName, isExtrapolation, hiddenColumns, isMobile }): any => {
  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: isMobile ? '11px' : '14px',
  };
  return [
    {
      title: 'Product name',
      width: isMobile ? 237 : 292,
      minWidth: 237,
      key: 'barcode',
      hidden: pageName === 'company' || pageName === 'brand',
      render: (value: any) => {
        return (
          <>
            <p style={defaultStyles}>{value.product_name}</p>
            <p style={{ color: '#A9A9A9', ...defaultStyles }}>
              {value.brand} | {value.local_company}
            </p>
          </>
        );
      },
      fixed: isMobile ? undefined : 'left',
    },
    {
      title: 'Barcode',
      key: 'barcode',
      width: isMobile ? 110 : 130,
      minWidth: 110,
      hidden: pageName === 'company' || pageName === 'brand',
      render: (value: any) => {
        return <p style={defaultStyles}>{value?.barcode}</p>;
      },
    },
    {
      title: 'Brand',
      width: isMobile ? 176 : 192,
      minWidth: 176,
      key: 'brand',
      hidden: pageName !== 'brand',
      render: (value: any) => {
        return (
          <>
            <p style={defaultStyles}>{value.brand}</p>
            <p style={{ color: '#A9A9A9', ...defaultStyles }}>{value.local_company}</p>
          </>
        );
      },
      fixed: isMobile ? undefined : 'left',
    },
    {
      title: 'Local company',
      width: isMobile ? 170 : 188,
      minWidth: 170,
      maxWidth: 270,
      key: 'local_company',
      hidden: pageName === 'brand' || pageName === 'barcode',
      render: (value: any) => {
        return <p style={defaultStyles}>{value.local_company}</p>;
      },
      fixed: isMobile ? undefined : 'left',
    },
    {
      title: 'Value kzt',
      width: isMobile ? 158 : 200,
      minWidth: 158,
      align: 'right',
      hidden: hiddenColumns?.value?.value,
      render: (value: any) => {
        return (
          <div style={{ margin: 0, padding: 0 }}>
            <p style={{ textAlign: 'right', ...defaultStyles }}>
              {`${value?.value}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
            </p>
            <p
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                color: '#A9A9A9',
                ...defaultStyles,
              }}
            >
              Rank: {value.rank_value}&nbsp; | &nbsp;Share: {value.value_per} %
            </p>
          </div>
        );
      },
    },
    {
      title: 'Items',
      width: isMobile ? 158 : 200,
      minWidth: 158,
      align: 'right',
      hidden: hiddenColumns?.items?.value,
      render: (value: any) => {
        return (
          <>
            <p
              style={{
                textAlign: 'right',
                ...defaultStyles,
              }}
            >
              {`${value?.items}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
            </p>
            <p
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                color: '#A9A9A9',
                ...defaultStyles,
              }}
            >
              Rank: {value.rank_item}&nbsp; | &nbsp;Share: {value.item_per}%
            </p>
          </>
        );
      },
    },
    {
      title: 'Volume',
      width: isMobile ? 158 : 200,
      minWidth: 158,
      align: 'right',
      hidden: isTobacco() ? true : hiddenColumns?.volume?.value,
      render: (value: any) => {
        return (
          <>
            <p
              style={{
                textAlign: 'right',
                ...defaultStyles,
              }}
            >
              {`${value?.volume}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
            </p>
            <p
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                color: '#A9A9A9',
                ...defaultStyles,
              }}
            >
              Rank: {value.rank_volume}&nbsp; | &nbsp;Share: {value.volume_per}%
            </p>
          </>
        );
      },
    },
    {
      title: 'Avg. price',
      key: 'avg_price_per_pcs',
      align: 'right',
      width: isMobile ? 60 : 90,
      minWidth: 60,
      hidden: hiddenColumns?.avg_price_per_pcs?.value,
      render: (value: any) => {
        return <p style={defaultStyles}>{value?.avg_price_per_pcs}</p>;
      },
    },
    {
      title: 'Avg. price per litre',
      key: 'avg_price_per_litre',
      align: 'right',
      width: isMobile ? 85 : 95,
      minWidth: 85,
      hidden: isTobacco() ? true : hiddenColumns?.avg_price_per_litre?.value,
      render: (value: any) => {
        return <p style={defaultStyles}>{value?.avg_price_per_litre}</p>;
      },
    },
    {
      title: 'Numeric distribution',
      key: 'numeric_dist',
      align: 'right',
      width: 100,
      minWidth: 100,
      hidden: hiddenColumns?.numeric_dist?.value,
      render: (value: any) => {
        return <p style={defaultStyles}>{value?.numeric_dist}</p>;
      },
    },
    {
      title: 'Weighted distribution',
      key: 'weight',
      align: 'right',
      width: 100,
      minWidth: 100,
      hidden: hiddenColumns?.weight?.value,
      render: (value: any) => {
        return <p style={defaultStyles}>{value?.weight}</p>;
      },
    },
    {
      title: 'CDE',
      key: 'cde',
      align: 'right',
      width: 60,
      minWidth: 60,
      hidden: hiddenColumns?.cde?.value,
      render: (value: any) => {
        return <p style={defaultStyles}>{value?.cde}</p>;
      },
    },
    {
      title: 'Stores',
      align: 'right',
      width: 70,
      minWidth: 70,
      hidden: isExtrapolation || hiddenColumns?.stores?.value,
      render: (value: any) => {
        return <p style={defaultStyles}>{`${value?.stores}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}</p>;
      },
    },
  ];
};
