import { Checkbox, Form } from 'antd';

type Props = {
  isModal: boolean;
};

const defaultStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 12,
};

export const SalesFormItems = ({ isModal }: Props) => {
  return (
    <>
      Sales statistics:
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['sales_statistics_local_company']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View company reports</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['sales_statistics_brand']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View brand reports</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['sales_statistics_barcode']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View SKU reports</label>
      </Form.Item>
      Trends:
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['trends_local_company']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View company reports</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['trends_brand']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View brand reports</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['trends_barcode']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View barcode reports</label>
      </Form.Item>
      Comparison:
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['comparison_local_company']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View company reports</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['comparison_brand']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View brand reports</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['comparison_barcode']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;View barcode reports</label>
      </Form.Item>
    </>
  );
};
