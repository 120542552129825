import { useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Drawer, Dropdown, Menu } from 'antd';
import { FilterOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import qs from 'qs';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';

import { CustomFilter } from '../../components/filter/CustomFilter';
import FilterService from '../../services/filter';
import VpoSegmentationService from '../../services/vpoSegmentation';
import { downloadFile } from '../../utils/downloadFile';
import { FilterChips, Tag } from '../../components/filter-chips/filterChips';
import { SalesTable } from './components/SalesTable';
import { SupplyTable } from './components/SupplyTable';
import { debounce } from '../../utils/debounce';
import styles from './styles.module.scss';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import { openNotification } from '../../utils/lastThreeMonths';

const ExportMenuItems = (onDownload: (type: 'supply' | 'sale') => void) => (
  <Menu>
    <Menu.Item key="sale">
      <div onClick={() => onDownload('sale')}>Sale</div>
    </Menu.Item>
    <Menu.Item key="supply">
      <div onClick={() => onDownload('supply')}>Supply</div>
    </Menu.Item>
  </Menu>
);

export const VpoSegmentationPage = () => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [openFilter, setOpenFilter] = useState(false);
  // const [tags, setTags] = useState<Tag[]>([]);
  const [salesStep, setSalesStep] = useState<number>(6);
  const [supplyStep, setSupplyStep] = useState<number>(6);
  // const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);
  const defaultDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM')
    .split('-')
    .join('');

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const handleDownloadFile = (type: 'supply' | 'sale') => {
    setExportLoading(true);
    VpoSegmentationService.exportByType(type, {
      months: params?.months || defaultDate,
      brand: params?.brand,
      step: type === 'sale' ? salesStep : supplyStep,
      ...params,
    })
      .then((res) => {
        setIsOpenDonwloads(true);
        openNotification('File added to downloads folder', 'success');
        // downloadFile(res.data, `report_${type}_${params?.months || defaultDate}` || 'coexistence_report');
      })
      .finally(() => setExportLoading(false));
  };

  const setDebounceSaleState = debounce(setSalesStep, 2000);
  const debounceSaleChange = (event) => {
    setDebounceSaleState(Number(event.target.value));
  };
  const setDebounceSupplyState = debounce(setSupplyStep, 2000);
  const debounceSupplyChange = (event) => {
    setDebounceSupplyState(Number(event.target.value));
  };

  return (
    <div className={styles.company_layout_content}>
      <div className={styles.top_bar}>
        <div className={styles.wrapper}>
          {!isTabletOrMobile && <div className={styles.title}>VPO - Segmentation</div>}

          {isTabletOrMobile && (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
              <div>
                <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                  <CustomFilter pageName="co-existence" type="brand" onFilterChange={onFilterChange} onCancel={hide} />
                </Drawer>
                <Button
                  type="ghost"
                  className={styles.company_filter_btn}
                  onClick={onOpenFilter}
                  icon={<FilterOutlined />}
                />
              </div>
            </div>
          )}
          {!isTabletOrMobile && (
            <>
              <div style={{ marginLeft: '15px' }}>
                <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                  <CustomFilter pageName="co-existence" type="brand" onFilterChange={onFilterChange} onCancel={hide} />
                </Drawer>
                <Button
                  type="ghost"
                  className={styles.company_filter_btn}
                  onClick={onOpenFilter}
                  icon={<FilterOutlined />}
                >
                  &nbsp;&nbsp;Filter
                </Button>
              </div>
            </>
          )}
        </div>
        &nbsp;&nbsp;&nbsp;
        <Dropdown overlay={ExportMenuItems(handleDownloadFile)}>
          <Button type="default" className={styles.export_btn} loading={exportLoading}>
            <VerticalAlignBottomOutlined />
            Export
          </Button>
        </Dropdown>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: isTabletOrMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <SalesTable salesStep={salesStep} debounceChange={debounceSaleChange} />
        <SupplyTable step={supplyStep} debounceChange={debounceSupplyChange} />
      </div>
    </div>
  );
};
