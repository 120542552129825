import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Button, PaginationProps, Table, Tooltip } from 'antd';
import { format, parseISO } from 'date-fns';
import { ColumnsType } from 'antd/lib/table';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import DownloadsService from '../../services/downloads';
import { useMediaQuery } from 'react-responsive';

interface DataType {
  key: React.Key;
  name: string;
}

type Props = {
  open: boolean;
};

const defaultStyles: React.CSSProperties = {
  margin: 0,
  padding: 0,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const DownloadsDrawer = ({ open }: Props) => {
  const queryClient = useQueryClient();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(1);

  const { data, isFetching } = useQuery(['export', open, page], ({ signal }) => {
    return DownloadsService.getDownloadsList({ page, limit: 10 }, { signal });
  });

  const onPageChange: PaginationProps['onChange'] = (page) => {
    setPage(page);
  };

  const getData = useCallback(() => {
    queryClient.refetchQueries('export', { active: true });
  }, [queryClient]);

  useEffect(() => {
    if (data) {
      setTableData(data?.results);
      setTotal(data?.count);
    }
  }, [data]);

  // const downloadAndShare = async (link: string) => {
  //   if (navigator.share) {
  //     await navigator.share({
  //       title: 'Excel File',
  //       text: 'Check out this Excel file',
  //       url: link,
  //     });
  //     console.log('File shared successfully');
  //   } else {
  //     console.error('Web Share API is not supported in your browser');
  //   }
  // };

  // const downloadFile = async (url, fileName) => {
  //   try {
  //     const response = await fetch(url);
  //     if (!response.ok) {
  //       throw new Error(`Failed to fetch file: ${response.statusText}`);
  //     }

  //     const blob = await response.blob();
  //     const file = new File([blob], fileName, { type: blob.type });
  //     return file;
  //   } catch (error) {
  //     console.error('Error downloading the file:', error);
  //     throw error;
  //   }
  // };

  // const handleShare = async (url, fileName) => {
  //   if (navigator.share) {
  //     try {
  //       const file = await downloadFile(url, fileName);
  //       if (file) {
  //         await navigator.share({
  //           title: 'Excel File',
  //           text: 'Here is the file you requested.',
  //           files: [file],
  //         });
  //         console.log('File shared successfully');
  //       } else {
  //         console.error('Failed to create file for sharing');
  //       }
  //     } catch (error) {
  //       console.error('Error sharing the file:', error);
  //     }
  //   } else {
  //     console.error('Web Share API is not supported in this browser');
  //   }
  // };

  const columns = useMemo<ColumnsType<DataType>>(
    () => [
      {
        title: 'Name',
        key: 'file_name',
        width: 100,
        render: (value: any) => {
          return (
            <Tooltip placement="top" title={value.file_name} overlayInnerStyle={{ fontSize: 11 }}>
              {value.status === 'COMPLETED' ? (
                <a href={value.link}>{value.file_name}</a>
              ) : (
                <p style={defaultStyles}>{value.file_name}</p>
              )}
            </Tooltip>
          );
        },
        fixed: 'left',
      },
      {
        title: 'Created',
        key: 'create_time',
        width: 100,
        render: (value: any) => {
          return format(parseISO(value.create_time), 'yyyy-MM-dd, HH:mm:ss');
        },
        align: 'center',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 50,
        align: 'center',
        render: (value: any) => {
          return (
            (value === 'COMPLETED' && <CheckCircleOutlined style={{ fontSize: 18, color: 'green' }} />) ||
            (value === 'FAILED' && <CloseCircleOutlined style={{ fontSize: 18, color: 'red' }} />) ||
            (value === 'IN_PROGRESS' && <ClockCircleOutlined style={{ fontSize: 18, color: 'orange' }} />)
          );
        },
      },
    ],
    []
  );
  return (
    <>
      {!isTabletOrMobile && (
        <Button icon={<SyncOutlined />} onClick={getData} loading={isFetching} disabled={isFetching}>
          Update
        </Button>
      )}
      <Table
        columns={columns}
        dataSource={tableData}
        loading={isFetching}
        pagination={{
          pageSize: 10,
          defaultPageSize: 10,
          total: total,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
        scroll={{ x: isTabletOrMobile ? '100%' : 'auto' }}
        size="small"
      />
    </>
  );
};
