import { Form, Switch, Typography } from 'antd';
import styles from './styles.module.scss';

const { Text } = Typography;
type Props = {
  isModal: boolean;
};

export const ManageFormItems = ({ isModal }: Props) => {
  return (
    <>
      <div className={styles.manage}>
        <div className={styles.item}>
          <Text type="secondary">Roles</Text>
          <Form.Item
            valuePropName="checked"
            name={['roles', 'can_create']}
            label="Create"
            labelCol={{ span: isModal ? 21 : 12 }}
            labelAlign="left"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name={['roles', 'can_edit']}
            label="Edit"
            labelCol={{ span: isModal ? 21 : 12 }}
            labelAlign="left"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name={['roles', 'can_read']}
            label="View"
            labelCol={{ span: isModal ? 21 : 12 }}
            labelAlign="left"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name={['roles', 'can_delete']}
            label="Delete"
            labelCol={{ span: isModal ? 21 : 12 }}
            labelAlign="left"
          >
            <Switch />
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Text type="secondary">Users</Text>
          <Form.Item
            valuePropName="checked"
            name={['users', 'can_create']}
            label="Create"
            labelCol={{ span: isModal ? 21 : 12 }}
            labelAlign="left"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name={['users', 'can_edit']}
            label="Edit"
            labelCol={{ span: isModal ? 21 : 12 }}
            labelAlign="left"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name={['users', 'can_read']}
            label="View"
            labelCol={{ span: isModal ? 21 : 12 }}
            labelAlign="left"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name={['users', 'can_delete']}
            label="Delete"
            labelCol={{ span: isModal ? 21 : 12 }}
            labelAlign="left"
          >
            <Switch />
          </Form.Item>
        </div>
      </div>
      <div className={styles.manage}>
        <div className={styles.item}>
          <Form.Item
            valuePropName="checked"
            name={['journal']}
            label="View user logs"
            labelCol={{ span: isModal ? 21 : 16 }}
            labelAlign="left"
          >
            <Switch />
          </Form.Item>
        </div>
      </div>
    </>
  );
};
