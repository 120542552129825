import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Collapse, Form, Input, notification, TreeSelect, Typography } from 'antd';

import FilterService from '../../../services/filter';
import PermissionsService from '../../../services/permissions';
import { SalesFormItems } from '../components/SalesFormItems';

import { ShareByCitiesFormItemsNew } from '../components/ShareByCitiesFormItemsNew';
import { StoreFormItemsNew } from '../components/StoreFormItemsNew';
import { AnalysisFormItemsNew } from '../components/AnalysisFormItemsNew';
import { ManageFormItemsNew } from '../components/ManageFormItemsNew';
import styles from './styles.module.scss';

const { Panel } = Collapse;

type Props = {
  isModal?: boolean;
  link?: string;
  close?: () => void;
  id?: string;
  onCloseEditDrawer?: () => void;
};

export const CreateRoleNew = ({ isModal, link, close, id, onCloseEditDrawer }: Props) => {
  const { roleId } = useParams<{ roleId: string }>();
  const history = useHistory();
  const [form] = Form.useForm();
  const [regions, setRegions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userCompanyId = JSON.parse(localStorage.getItem('user'))?.company?.id;

  const onNavigateTable = () => {
    if (isModal) {
      history.push(link);
      close();
    } else {
      // history.push('/permissions/roles');
      onCloseEditDrawer();
      form.resetFields();
    }
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.success({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  const onFinish = (values: any) => {
    const permissions: any = [];
    Object.entries(values).map(([key, value]) => {
      if (key !== 'name' && key !== 'regions') {
        if (key === 'users' || key === 'roles') {
          Object.entries(value).map(([objKey, objVal]) => {
            if (objVal) {
              permissions.push({
                module: key,
                action: objKey,
              });
            }
          });
        } else if (key === 'sales_by_stores' && value) {
          permissions.push({
            module: 'sales_by_stores',
            action: 'can_read',
          });
          permissions.push({
            module: 'store_statistics',
            action: 'can_read',
          });
        } else if (value) {
          permissions.push({
            module: key,
            action: 'can_read',
          });
        }
      }
      return permissions;
    });

    if (roleId || id) {
      PermissionsService.editRoleById(roleId || id, {
        regions: values.regions,
        name: values.name,
        permissions: permissions,
        company: userCompanyId,
      }).then((res) => {
        openNotification('Role changed!');
        onNavigateTable();
        // history.push('/permissions/roles');
      });
    } else {
      PermissionsService.createRole({
        company: userCompanyId,
        regions: values?.regions,
        name: values?.name,
        permissions: permissions,
      }).then((res) => {
        openNotification('Role created!');
        if (isModal) {
          history.push(link);
          close();
        } else {
          history.push('/permissions/roles');
          onNavigateTable();
        }
      });
    }
  };

  const getRegions = useCallback(() => {
    setLoading(true);
    FilterService.getCities(true)
      .then((res) => {
        setRegions(res.data.results);
      })
      .catch((err) => setRegions([]))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getRegions();
    return () => {
      setRegions([]);
    };
  }, []);

  useEffect(() => {
    if (roleId || id) {
      PermissionsService.getRoleById(roleId || id).then((res) => {
        const permissions = Object.keys(res.data.permissions)
          .map((item) => {
            if (item === 'roles' || item === 'users') {
              const arr = res.data.permissions[item]
                ?.map((el: any) => {
                  return {
                    [el]: true,
                  };
                })
                .reduce((obj: any, item: any) => Object.assign(obj, item), {});
              return {
                [item]: arr,
              };
            } else {
              return { [item]: true };
            }
          })
          .reduce((obj: any, item: any) => Object.assign(obj, item), {});

        form.setFieldsValue({
          ...res.data,
          ...permissions,
          regions: res?.data?.regions.map(String),
        });
      });
    }
  }, [roleId, id]);

  return (
    <div>
      <Form form={form} onFinish={onFinish} className={styles.form}>
        <div>
          <div>
            <Typography style={{ margin: 0, padding: 0 }}>Role name</Typography>
            <Form.Item name="name" rules={[{ required: true }]} labelAlign="left">
              <Input />
            </Form.Item>
          </div>

          <div>
            <Typography>Region</Typography>
            <Form.Item name="regions" labelAlign="left" rules={[{ required: true }]}>
              <TreeSelect
                treeData={regions}
                treeCheckable={true}
                labelInValue={false}
                placeholder="Please select"
                maxTagCount="responsive"
                loading={loading}
                allowClear
              />
            </Form.Item>
          </div>

          {/* <Tabs className="card-tabs">
            <TabPane tab="Sales" key="sales" forceRender>
              <SalesFormItems isModal={isModal} />
            </TabPane>
            <TabPane tab="Share by cities" key="share" forceRender>
              <ShareByCitiesFormItems isModal={isModal} />
            </TabPane>
            <TabPane tab="Stores" key="stores" forceRender>
              <StoreFormItems isModal={isModal} />
            </TabPane>
            <TabPane tab="Analysis" key="analysis" forceRender>
              <AnalysisFormItems isModal={isModal} permission={userCompanyId} />
            </TabPane>
            <TabPane tab="Manage" key="manage" forceRender>
              <ManageFormItems isModal={isModal} />
            </TabPane>
          </Tabs> */}
          <Collapse className="card-tabs">
            <Panel header="Sales Report" key="1" forceRender>
              <SalesFormItems isModal={isModal} />
            </Panel>
            <Panel header="Share by cities" key="2" forceRender>
              <ShareByCitiesFormItemsNew isModal={isModal} />
            </Panel>
            <Panel header="Stores" key="3" forceRender>
              <StoreFormItemsNew isModal={isModal} />
            </Panel>
            <Panel header="Analysis" key="4" forceRender>
              <AnalysisFormItemsNew isModal={isModal} permission={userCompanyId} />
            </Panel>
            <Panel header="Manage" key="5" forceRender>
              <ManageFormItemsNew isModal={isModal} />
            </Panel>
          </Collapse>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 18 }}>
          <Button type="default" htmlType="button" onClick={onNavigateTable}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};
