import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Space } from 'antd';

const { RangePicker } = DatePicker;

type Props = {
  name: string;
};

export const MultipleDates = ({ name }: Props) => {
  return (
    <Form.List name={name}>
      {(fields: any[], { add, remove }: { add: any; remove: any }) => (
        <>
          {fields.map(({ key, name, ...restField }: { key: any; name: string }, index: number) => (
            <Space key={key} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Item
                {...restField}
                label={`Period ${index + 1}`}
                name={[name, 'period']}
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <RangePicker format="YYYY-MM-DD" picker="date" allowClear={true} />
              </Form.Item>
              <CloseOutlined onClick={() => remove(name)} style={{ marginTop: 10 }} />
            </Space>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} disabled={fields.length >= 5}>
              Add period +
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};
