import { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Collapse, Drawer, message, notification, PaginationProps, Table } from 'antd';
import { useQuery, useQueryClient } from 'react-query';
import qs from 'qs';

import PermissionsService from '../../../services/permissions';
import { ConfirmationModal } from '../../../components/confirmationModal/ConfirmationModal';
import { useUserColumns } from './useUserColumns';
import { useRoleColumns } from './useRoleColumns';
import styles from './styles.module.scss';
// import { SalesFormItems } from './SalesFormItemsTest';
import { CreateRoleNew } from '../CreateRole/CreateRoleNew';
import { CreateUserNew } from '../CreateUser/CreateUserNew';

const { Panel } = Collapse;

type Props = {
  pageName: string;
  column?: any;
  data?: any;
  loading?: boolean;
};

export const CustomTable = ({ pageName }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const { roleId } = useParams<{ roleId: string }>();
  const { userId } = useParams<{ userId: string }>();
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [errorCode, setErrorCode] = useState<any>();
  const [userIsActive, setIsUserActive] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [isUserEditOpen, setIsUserEditOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const canEdit = JSON.parse(localStorage.getItem('user'))?.role?.permissions[pageName]?.includes('can_edit');
  // const canDelete = JSON.parse(localStorage.getItem('user'))?.role?.permissions[pageName]?.includes('can_delete');
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;

  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    history.replace({ pathname: history.location.pathname, search: queryParams.toString() });
  };

  const { data, isFetching, error } = useQuery(['usersTable', pageName, params], ({ signal }) => {
    switch (pageName) {
      case 'users':
        return PermissionsService.getUsers({ limit: +params.limit || 10, ...params }, { signal });
      case 'roles':
        return PermissionsService.getRoles({ limit: +params.limit || 10, ...params }, { signal });
      default:
        return;
    }
  });

  const getData = useCallback(() => {
    queryClient.refetchQueries('usersTable', { active: true });
  }, [queryClient]);

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  const handleActivateOrDeactivateUser = () => {
    PermissionsService.activateOrDeactivateUser(id, userIsActive)
      .then((res) => getData())
      .catch((err) => openNotification(err.message))
      .finally(() => setOpenModal(false));
  };

  const handleActivateOrDeactivateRole = () => {
    PermissionsService.activateOrDeactivateRole(id, userIsActive)
      .then((res) => getData())
      .catch((err) => openNotification(err.message))
      .finally(() => setOpenModal(false));
  };

  const handleEditRole = () => {};

  const handleDeleteUser = () => {
    PermissionsService.deleteUserById(id)
      .then((res) => getData())
      .catch((err) => message.error(err.message))
      .finally(() => setOpenModal(false));
  };

  const handleDeleteRole = () => {
    PermissionsService.deleteRoleById(id)
      .then((res) => getData())
      .catch((err) => message.error(err.message))
      .finally(() => setOpenModal(false));
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const closeEditDrawer = () => {
    setIsEditOpen(false);
    setId(null);
  };

  const closeUserEditDrawer = () => {
    setIsUserEditOpen(false);
    setId(null);
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setErrorCode('You do not have permission ');
        setTableData([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
        setTotal(0);
      }
    }
  }, [data, error]);

  useEffect(() => {
    if (roleId) {
      setIsEditOpen(true);
      setId(roleId);
    }
  }, [roleId]);

  useEffect(() => {
    if (userId) {
      setIsUserEditOpen(true);
      setId(userId);
    }
  }, [userId]);

  const columnsParams = useMemo(
    () => ({ canEdit, setIsUserActive, setId, handleOpenModal, setIsEditOpen }),
    [canEdit, setIsUserActive, setId, handleOpenModal, setIsEditOpen]
  );

  const columnsUserParams = useMemo(
    () => ({ canEdit, setIsUserActive, setId, handleOpenModal, setIsUserEditOpen }),
    [canEdit, setIsUserActive, setId, handleOpenModal, setIsUserEditOpen]
  );

  const columns = useUserColumns(columnsUserParams);
  const roleColumns = useRoleColumns(columnsParams);

  return (
    <>
      {pageName === 'roles' && (
        <div style={{ marginBottom: '24px' }}>
          <Button type="primary" onClick={() => setIsEditOpen(true)}>
            + Create new role
          </Button>
        </div>
      )}
      {pageName === 'users' && (
        <div style={{ marginBottom: '24px' }}>
          <Button type="primary" onClick={() => setIsUserEditOpen(true)}>
            + Create new user
          </Button>
        </div>
      )}
      <Table
        columns={pageName === 'users' ? columns : roleColumns}
        style={{ width: pageName === 'roles' ? '806px' : 'auto' }}
        dataSource={tableData}
        loading={isFetching}
        className={styles.ant_table}
        sticky
        pagination={{
          pageSize: limit,
          defaultPageSize: 10,
          total: total,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
        bordered
        size="middle"
      />
      <ConfirmationModal
        open={openModal}
        close={handleCloseModal}
        title={``}
        description={`Are you sure you want to  ${!userIsActive ? 'block' : 'unblock'} with id - ${id} ?`}
        onSubmit={pageName === 'users' ? handleActivateOrDeactivateUser : handleActivateOrDeactivateRole}
      />
      <Drawer title="Edit role" width="400px" placement="right" onClose={closeEditDrawer} open={isEditOpen}>
        <CreateRoleNew id={id} onCloseEditDrawer={closeEditDrawer} />
      </Drawer>
      <Drawer title="Edit user" width="400px" placement="right" onClose={closeUserEditDrawer} open={isUserEditOpen}>
        <CreateUserNew id={id} onCloseEditDrawer={closeUserEditDrawer} />
      </Drawer>
    </>
  );
};
