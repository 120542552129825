import { Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';

export const ItemsMobile = (permissions: any): MenuProps['items'] => [
  {
    label: 'Sales Report',
    key: 'sales',
    children: [
      (permissions?.sales_statistics_local_company?.includes('can_read') ||
        permissions?.sales_statistics_brand?.includes('can_read') ||
        permissions?.sales_statistics_barcode?.includes('can_read')) && {
        type: 'group',
        label: 'Sales statistics',
        children: [
          permissions?.sales_statistics_local_company?.includes('can_read') && {
            label: <Link to="/sales/company">By company</Link>,
            key: '/sales/company',
          },
          permissions?.sales_statistics_brand?.includes('can_read') && {
            label: <Link to="/sales/brand">By brand</Link>,
            key: '/sales/brand',
          },
          permissions?.sales_statistics_barcode?.includes('can_read') && {
            label: <Link to="/sales/barcode">By barcode</Link>,
            key: '/sales/barcode',
          },
        ],
      },
      (permissions?.trends_local_company?.includes('can_read') ||
        permissions?.trends_brand?.includes('can_read') ||
        permissions?.trends_barcode?.includes('can_read')) && {
        type: 'group',
        label: 'Trends',
        children: [
          permissions?.trends_local_company?.includes('can_read') && {
            label: <Link to="/trends/company/value">By company</Link>,
            key: '/trends/company',
          },
          permissions?.trends_brand?.includes('can_read') && {
            label: <Link to="/trends/brand/value">By brand</Link>,
            key: '/trends/brand',
          },
          permissions?.trends_barcode?.includes('can_read') && {
            label: <Link to="/trends/barcode/value">By barcode</Link>,
            key: '/trends/barcode',
          },
        ],
      },
      (permissions?.comparison_local_company?.includes('can_read') ||
        permissions?.comparison_brand?.includes('can_read') ||
        permissions?.comparison_barcode?.includes('can_read')) && {
        type: 'group',
        label: 'Сomparison',
        children: [
          permissions?.comparison_local_company?.includes('can_read') && {
            label: <Link to="/comparison/company/value">By company</Link>,
            key: '/comparison/company',
          },
          permissions?.comparison_brand?.includes('can_read') && {
            label: <Link to="/comparison/brand/value">By brand</Link>,
            key: '/comparison/brand',
          },
          permissions?.comparison_barcode?.includes('can_read') && {
            label: <Link to="/comparison/barcode/value">By barcode</Link>,
            key: '/comparison/barcode',
          },
        ],
      },
    ],
  },
  {
    label: 'Share by cities',
    key: 'trends',
    children: [
      (permissions?.share_trends_local_company?.includes('can_read') ||
        permissions?.share_trends_brand?.includes('can_read') ||
        permissions?.share_trends_barcode?.includes('can_read')) && {
        type: 'group',
        label: 'Trends',
        children: [
          permissions?.share_trends_local_company?.includes('can_read') && {
            label: <Link to="/trends-share/company">By company</Link>,
            key: '/trends-share/company',
          },
          permissions?.share_trends_brand?.includes('can_read') && {
            label: <Link to="/trends-share/brand">By brand</Link>,
            key: '/trends-share/brand',
          },
          permissions?.share_trends_barcode?.includes('can_read') && {
            label: <Link to="/trends-share/barcode">By barcode</Link>,
            key: '/trends-share/barcode',
          },
        ],
      },
      (permissions?.market_share_local_company?.includes('can_read') ||
        permissions?.market_share_brand?.includes('can_read') ||
        permissions?.market_share_barcode?.includes('can_read')) && {
        type: 'group',
        label: 'Market',
        children: [
          permissions?.market_share_local_company?.includes('can_read') && {
            label: <Link to="/market-share/company">By company</Link>,
            key: '/market-share/company',
          },
          permissions?.market_share_brand?.includes('can_read') && {
            label: <Link to="/market-share/brand">By brand</Link>,
            key: '/market-share/brand',
          },
          permissions?.market_share_barcode?.includes('can_read') && {
            label: <Link to="/market-share/barcode">By barcode</Link>,
            key: '/market-share/barcode',
          },
        ],
      },
      (permissions?.share_comparison_local_company?.includes('can_read') ||
        permissions?.share_comparison_brand?.includes('can_read') ||
        permissions?.share_comparison_barcode?.includes('can_read')) && {
        type: 'group',
        label: 'Comparison',
        children: [
          permissions?.share_comparison_local_company?.includes('can_read') && {
            label: <Link to="/comparison-share/company">By company</Link>,
            key: '/comparison-share/company',
          },
          permissions?.share_comparison_brand?.includes('can_read') && {
            label: <Link to="/comparison-share/brand">By brand</Link>,
            key: '/comparison-share/brand',
          },
          permissions?.share_comparison_barcode?.includes('can_read') && {
            label: <Link to="/comparison-share/barcode">By barcode</Link>,
            key: '/comparison-share/barcode',
          },
        ],
      },
    ],
  },
  permissions?.sales_by_stores?.includes('can_read') && {
    label: <Link to="/stores">Stores</Link>,
    key: '/stores',
  },
  {
    label: <Link to="/downloads">Downloads</Link>,
    key: '/downloads',
  },
  {
    label: 'Analytics',
    key: 'analytics',
    children: [
      {
        type: 'group',
        label: '',
        children: [
          permissions?.abc_analysis_barcode?.includes('can_read') && {
            label: <Link to={`/abc-analysis/value`}>ABC analysis</Link>,
            key: `/abc-analysis`,
          },
          permissions?.out_of_stock_barcode?.includes('can_read') && {
            label: <Link to="/out-of-stock">Out of stock</Link>,
            key: '/out-of-stock',
          },
          (permissions?.buying_behavior_price?.includes('can_read') ||
            permissions?.buying_behavior_week?.includes('can_read') ||
            permissions?.buying_behavior_volume?.includes('can_read') ||
            permissions?.coexistence?.includes('can_read')) && {
            label: <Link to="/buying-behavior">Buying behavior</Link>,
            key: '/buying-behavior',
          },
          permissions?.price_elasticity?.includes('can_read') && {
            label: <Link to="/price-elasticity">Price elasticity</Link>,
            key: '/price-elasticity',
          },
          permissions?.product_comparison?.includes('can_read') && {
            label: <Link to="/comparison-product">Sell in / Sell out Prices</Link>,
            key: '/comparison-product',
          },
          permissions?.coexistence?.includes('can_read') && {
            label: <Link to="/co-existence">Co-Existence</Link>,
            key: '/co-existence',
          },
          permissions?.coexistence?.includes('can_read') && {
            label: <Link to="/co-existence-trends">Co-Existence Trends</Link>,
            key: '/co-existence-trends',
          },
          permissions?.vpo_segmentation?.includes('can_read') && {
            label: <Link to="/vpo-segmentation">VPO Segmentation</Link>,
            key: '/vpo-segmentation',
          },
          permissions?.vpo_trend?.includes('can_read') && {
            label: <Link to="/vpo-trends">VPO Segmentation Trends</Link>,
            key: '/vpo-trends',
          },
          // permissions?.basket_analysis?.includes('can_read') && {
          //   label: <Link to="/basket-analysis">Basket Analysis</Link>,
          //   key: '/basket-analysis',
          // },
        ],
      },
    ],
  },
  // (permissions?.users?.includes('can_read') ||
  //   permissions?.roles?.includes('can_read') ||
  //   permissions?.journal?.includes('can_read')) && {
  //   label: 'Manage',
  //   key: 'permissions',
  //   children: [
  //     permissions?.users?.includes('can_read') && {
  //       label: <Link to="/permissions/users">Users</Link>,
  //       key: '/permissions/users',
  //     },
  //     permissions?.roles?.includes('can_read') && {
  //       label: <Link to="/permissions/roles">Roles</Link>,
  //       key: '/permissions/roles',
  //     },
  //     permissions?.journal?.includes('can_read') && {
  //       label: <Link to="/users-logs">Users logs</Link>,
  //       key: '/users-logs',
  //     },
  //   ],
  // },
];

export const UserMenuItems = (logout?: () => void, openFilter?: () => void) => (
  <Menu>
    <Menu.Item key="password">
      <div onClick={openFilter}>Change password</div>
    </Menu.Item>
    <Menu.Item key="logout">
      <div onClick={logout}>
        <LogoutOutlined /> Logout
      </div>
    </Menu.Item>
  </Menu>
);
