import { Modal, Radio, RadioChangeEvent, Space } from 'antd';
import { useState } from 'react';
import info from '../../../assets/abc.png';

type Props = {
  open: boolean;
  close: () => void;
};

export const InformationModal = ({ open, close }: Props) => {
  const [tabValue, setTabValue] = useState<'en' | 'ru'>('ru');

  const changeTabPosition = (e: RadioChangeEvent) => {
    setTabValue(e.target.value);
  };

  return (
    <Modal title={''} open={open} onCancel={close} width={600} style={{ top: 20 }} footer={null}>
      <Space style={{ marginTop: 16, marginBottom: 16 }}>
        <Radio.Group value={tabValue} onChange={changeTabPosition}>
          <Radio.Button value="ru">RU</Radio.Button>
          <Radio.Button value="en">EN</Radio.Button>
        </Radio.Group>
      </Space>
      {tabValue === 'ru' ? (
        <div>
          <b>Что такое ABC- анализ</b>
          <p>ABC-анализ — метод, позволяющий классифицировать продукции компании по степени их важности.</p>
          <p>
            В основу ABC анализа легли диаграммы Парето, которые показывают, что только 20 % всех товаров дают 80 %
            выручки (прибыли) предприятия. Этот принцип определил алгоритм проведения АВС анализа, который предполагает
            разделение всей продукции предприятия на три группы: А, В и С:
          </p>
          <p>
            <strong>Группа А</strong> – сюда относят товары, которые приносят 80 % выручки, это примерно 20 % от всех
            товаров.
          </p>
          <p>
            <strong>Группа В</strong> – товары, которые приносят 15 % выручки, как правило, это 30 % от всех товаров.
          </p>
          <p>
            <strong>Группа С</strong> – товары, которые приносят магазину 5 % выручки, это оставшиеся 50 % товаров.
          </p>
        </div>
      ) : (
        <div>
          <b>What is ABC analysis</b>
          <p>
            ABC analysis is a method that allows you to classify a company's products according to their importance.
          </p>
          <p>
            ABC analysis is based on Pareto charts, which show that only 20% of all goods provide 80% of the company's
            revenue (profit). This principle determined the algorithm for conducting ABC analysis, which involves
            dividing all products of an enterprise into three groups: A, B and C:
          </p>
          <p>
            <strong>Group А</strong> – goods that bring in 80% of revenue, which is approximately 20% of all goods.
          </p>
          <p>
            <strong>Group В</strong> – goods that bring 15% of revenue, as a rule, this is 30% of all goods.
          </p>
          <p>
            <strong>Group С</strong> – products that bring the store 5% of revenue; these are the remaining 50% of
            goods.
          </p>
        </div>
      )}

      <img src={info} alt="" width={577} height={316} style={{ marginLeft: '-20px', margin: 0, padding: 0 }} />
    </Modal>
  );
};
