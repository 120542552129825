import { AxiosRequestConfig, AxiosResponse } from 'axios';
import fetchClient from './axiosInstance';

interface ComparisonShareQueryParams {
  period_1: string;
  period_2: string;
  period_3?: string;
  period_4?: string;
  period_5?: string;
  city?: string;
  brand?: string;
  barcode?: string;
  local_company?: string;
  size_of_package?: string;
  subcategory?: string;
  type_of_package?: string;
  active_stores?: boolean;
  validated_stores?: boolean;
}
export default class ComparisonShareService {
  static async getComparisonShareByCompany(
    params: ComparisonShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/share_comparison/local_company/`, { params, ...customConfig });
  }

  static async getComparisonShareByBrand(
    params: ComparisonShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/share_comparison/brand/`, { params, ...customConfig });
  }

  static async getComparisonShareByBarcode(
    params: ComparisonShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/share_comparison/barcode/`, { params, ...customConfig });
  }

  static async exportByType(
    type: string,
    params: ComparisonShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/share_comparison/${type}/export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }
}
