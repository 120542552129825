import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Drawer } from 'antd';
import qs from 'qs';
import { FilterOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

import { ComparisonProductTable } from './components/ComparisonProductTable';
import { ComparisonProductFilter } from './components/ComparisonProductFilter';
import ComparisonProductService from '../../services/comparisonProduct';
// import { downloadFile } from '../../utils/downloadFile';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import { openNotification } from '../../utils/lastThreeMonths';
import styles from './styles.module.scss';

export const ComparisonProductPage = () => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [openFilter, setOpenFilter] = useState(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;

  const handleDownloadFile = () => {
    setExportLoading(true);
    ComparisonProductService.export({
      period_after: params?.period_after,
      period_before: params?.period_before,
      barcode_1: params?.barcode_1,
      barcode_2: params?.barcode_2,
      ...params,
    })
      .then((res) => {
        setIsOpenDonwloads(true);
        openNotification('File added to downloads folder', 'success');
        // downloadFile(res.data, `comparison_product`);
      })
      .finally(() => setExportLoading(false));
  };

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  return (
    <>
      <div className={styles.company_layout_content}>
        <div className={styles.top_bar}>
          <div className={styles.wrapper}>
            {!isTabletOrMobile && <div className={styles.title}>Sell in / Sell out Prices</div>}
            {isTabletOrMobile && (
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
                <div>
                  <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                    <ComparisonProductFilter onFilterChange={onFilterChange} onCancel={hide} />
                  </Drawer>
                  <Button
                    type="ghost"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  />
                </div>
              </div>
            )}
            {!isTabletOrMobile && (
              <>
                <div style={{ marginLeft: '15px' }}>
                  <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                    <ComparisonProductFilter onFilterChange={onFilterChange} onCancel={hide} />
                  </Drawer>
                  <Button
                    type="ghost"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  >
                    &nbsp;&nbsp;Filter
                  </Button>
                </div>
              </>
            )}
          </div>
          <Button type="default" className={styles.export_btn} onClick={handleDownloadFile} loading={exportLoading}>
            <VerticalAlignBottomOutlined />
            Export
          </Button>
        </div>
        <ComparisonProductTable />
      </div>
      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
    </>
  );
};
